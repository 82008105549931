export default class DutyHolidayRulesTableItem {
    holidayRulesId = 0;
    holidayStatus = 0;
    holidayRules = 0;
    holidayRulesName = "";
    createName = "";
    createTime = "";
    modifyName = "";
    modifyTime = "";
}
