import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import DutyHolidayRulesTableItem from "@/entity/attendance/duty/DutyHolidayRulesTableItem";
import API from "@/api/attendance/duty/dutyHolidayRules";

@Options({
    name: "app-attendance-duty-batch",
})
export default class Batch extends BaseTableMixins<DutyHolidayRulesTableItem> implements Partial<BaseTableInterface<DutyHolidayRulesTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.editTableItem = new DutyHolidayRulesTableItem();
        this.editModalItem = new DutyHolidayRulesTableItem();
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    //修改弹窗配置
    public modModalSetting(): void {
        this.editModalTitle = "修改月份 (" + this.editModalItem.holidayRulesId + ")";
        this.editModalSubmitButtonName = "修改";
    }
    //修改提交检测
    public modSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.holidayRulesId)) return "月份不能为空！";
        return this.addSubmitChecking();
    }

    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.holidayRulesId)) return "月份不能为空！";
        return null;
    }

    //排序弹窗配置
    public sortModalSetting(): void {
        this.editSortBaseTitle = "月份排序";
    }

    //状态提示框配置
    public statusAlertConfig(): any {
        return {
            title: "月份状态",
            message: this.editModalItem.holidayRulesId + "<br>请确认修改状态？",
            submitButtonName: this.editModalItem.holidayStatus === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.holidayStatus = this.editModalItem.holidayStatus === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.holidayRulesId)) return "月份不能为空！";
        return null;
    }
}
